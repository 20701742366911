<template>
  <div class="table-responsive adaptive-table sentMessageTable" v-if="list.length > 0">
    <table class="table table-striped table-hover " style="width:100%">
      <thead>
        <tr>
          <th v-for="(item, key) in fields" :class="item === 'text' ? 'col-md-4' : ''" :key="key">{{ $t(item) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in list" :key="row.id">
          <td :data-label="$t('contact_count')">
            {{ row.count }}
          </td>
          <td :data-label="$t('completed')">
            {{ row.completed ? '✅' : '❌' }}&emsp;<button v-if="row.completed" class="btn btn-sm btn-primary" @click.prevent="showInfo(row.id)">{{ $t('details') }}</button>
          </td>
          <td :data-label="$t('date')">{{ humanDateTime(row.create_date, $i18n.locale) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { useHumanDate } from '@/application/composables/humanDate.js'

export default {
  name: 'LookupBulkHistoryTable',
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { humanDateTime, humanDate } = useHumanDate()
    const router = useRouter()

    const fields = ['contact_count', 'completed', 'date']
    const showInfo = (uuid) => {
      router.push({ name: 'lookup-history', params: { uuid: uuid } })
    }
    return {
      humanDateTime,
      humanDate,
      showInfo,
      fields,
    }
  },
}
</script>
